import {PropsWithChildren} from "react";
import clsx from "clsx";

/** Default page spacing for non-editor pages. */
const Page: React.FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => {
  return (
    <div className={clsx("p-6", className)}>
      {children}
    </div>
  )
}

export default Page;