'use client';

import Centered from "#/app/_components/Centered";
import { GlobalErrorMessage } from "#/app/_components/GlobalErrorMessage";

/**
 * https://beta.nextjs.org/docs/api-reference/file-conventions/error#global-errorjs
 * 
 * global-error.js replaces the root layout.js when active and so must define its own <html> and <body> tags.
 * 
 * This will not be called in `next dev`.
 */
export default function GlobalError({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  return (
    <GlobalErrorMessage reset={reset} />
  );
}
