'use client';

import {Email} from "#/app/_components/Email";
import {FC, useEffect} from "react";
import {useRouter} from "next/navigation";
import Centered from "#/app/_components/Centered";
import {Spinner} from "#/app/_components/Spinner";
import Page from "#/app/_components/Page";

export const GlobalErrorMessage: FC<{
  reset: () => void
}> = ({reset}) => {
  const router = useRouter();

  const handleRefresh = () => {
    window.location.reload();
  }

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
      // router.refresh();
    }, 2500);
    return () => clearInterval(interval);
  }, [router]);

  return (
    <Page>
      <Centered>
        <div className="flex flex-col space-y-5 items-center justify-center">
          <p>Loading...</p>
          <Spinner size={6}/>
        </div>
      </Centered>
    </Page>

    // <div className="text-center space-y-4">
    //   <h1 className="text-2xl font-bold">Refresh to continue.</h1>
    //   <p>
    //     Please click refresh to continue.
    //   </p>
    //   <button className="p-3 mt-3 border-border hover:border-white border-[0.5px] rounded-xl" onClick={handleRefresh}>
    //     Refresh page
    //   </button>
    // </div>
  )
};
